.banner {
  width: 100vw;
  margin: 0px;
  background-color: #8BFCF2;
  padding: 8px 0px;
  @media (max-width: 768px) {
    padding: 8px 44px;
  }
  &__title {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    line-height: 20px;
  }
}