.page-header {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #151c2f;
  &__title {
    font-family: 'Lato', sans-serif;
    font-size: 38px;
    line-height: 44px;
    font-weight: bolder;
    color: white;
    @media screen and (max-width: 575.98px) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  &__subtitle {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: white;
    @media screen and (max-width: 575.98px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 575.98px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
