.why-content {
  background-color: #f2fbff;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-right: 120px;
  padding-left: 120px;
  @media screen and (min-width: 1900px) {
    padding-right: 200px;
    padding-left: 200px;
  }
  @media screen and (max-width: 991.98px) {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 60px;
    padding-left: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 24px;
    padding-left: 24px;
  }
  &__nav {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: #111;
    font-weight: bolder;
    text-align: center;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    @media screen and (max-width: 991.98px) {
      font-size: 18px;
      line-height: 20px;
    }
    @media screen and (min-width: 1900px) {
      font-size: 36px;
      line-height: 40px;
    }
  }
  &__para {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #111;
    font-weight: 500;
    text-align: left;
    @media screen and (min-width: 1900px) {
      font-size: 22px;
      line-height: 32px;
    }
    @media screen and (max-width: 991.98px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media screen and (max-width: 767.98px) {
      font-size: 12px;
      line-height: 16px;
    }
    &--bold {
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      line-height: 28px;
      color: #111;
      font-weight: bolder;
      text-align: center;
      margin-top: 30px !important;
      margin-bottom: 30px !important;
      @media screen and (min-width: 1900px) {
        font-size: 22px;
        line-height: 32px;
      }
      @media screen and (max-width: 991.98px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media screen and (max-width: 767.98px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &--header {
      font-family: 'Lato', sans-serif;
      color: #111;
      font-weight: bolder;
      text-align: center;
      margin-top: 40px !important;
      margin-bottom: 40px !important;
      font-size: 24px;
      line-height: 32px;
      @media screen and (max-width: 991.98px) {
        font-size: 18px;
        line-height: 20px;
      }
      @media screen and (max-width: 767.98px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
