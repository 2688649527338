body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden !important;
}

p,
pre {
  margin: 0 !important;
  padding: 0 !important;
}

.root {
  padding: 0px;
}

.no-pad {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

a {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}

.terms-policy {
  padding: 60px 90px;
  &__content {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
  @media screen and (max-width: 991.98px) {
    padding: 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 40px 16px;
  }
}

.cover {
  background-color: #151c2f;
  &__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    color: #fafafa;
    padding-bottom: 4px !important;
    @media screen and (max-width: 767.98px) {
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 0px !important;
    }
  }
  &__launching {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: white;
    font-weight: 600;
    margin-bottom: 16px !important;
  }
  &__available {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: white;
    font-weight: 400;
    margin-bottom: 16px !important;
    @media screen and (max-width: 991.98px) {
      font-size: 14px;
      line-height: 28px;
    }
  }
  &__subtitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    line-height: 36px;
    color: #fafafa;
    padding-top: 8px !important;
    @media screen and (max-width: 1199.98px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media screen and (max-width: 991.98px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__check-status {
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    & > span {
      color: #76f8f7;
    }
  }
  &__store {
    height: 40px;
    width: 132px;
    @media screen and (max-width: 767.98px) {
    height: 30px;
      width: 100px;
    }
  }
  &__rating {
    height: intrinsic;
    width: 140px;
    @media screen and (max-width: 991.98px) {
      height: intrinsic;
      width: 100px;
    }
  }
  &__floating-content {
    position: absolute;
    left: 90px;
    z-index: 999;
    top: 28%;
  }
  &__input-container {
    padding-top: 24px;
    padding-bottom: 32px;
    @media screen and (max-width: 991.98px) {
      padding-top: 16px;
      padding-bottom: 24px;
    }
  }
  &__content {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 44px;
    @media screen and (min-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.max-width-container {
  max-width: 940px;
  @media screen and (max-width: 991px) {
    max-width: 728px;
  }
  @media screen and (max-width: 767.98px) {
    max-width: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.bg {
  background-color: #151c31;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
  @media screen and (max-width: 991.98px) {
    width: 120px;
    height: 120px;
  }
  @media screen and (max-width: 767.98px) {
    width: 100px;
    height: 100px;
  }
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 180px;
  height: 180px;
  border: 10px solid #76f8f9;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #76f8f9 transparent transparent transparent;
  @media screen and (max-width: 991.98px) {
    width: 120px;
    height: 120px;
  }
  @media screen and (max-width: 767.98px) {
    width: 100px;
    height: 100px;
  }
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
