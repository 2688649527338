.footer {
  background-color: #101524;
  padding: 32px 90px;
  @media screen and (max-width: 991.98px) {
    padding: 40px 24px;
  }
  &__title {
    font-family: 'Lato', sans-serif;
    color: #bbb;
    font-size: 20px;
    line-height: 1.875rem;
    font-weight: bolder;
    margin-bottom: 8px !important;
    @media screen and (max-width: 991.98px) {
      font-size: 16px;
    }
    @media screen and (max-width: 767.98px) {
      margin-top: 12px !important;
    }
  }
  &__link {
    font-family: 'Open Sans', sans-serif;
    color: white;
    font-size: 12px;
    text-decoration: none;
    line-height: 20px;
    display: block;
    margin-bottom: 4px !important;
    &:hover {
      text-decoration: none;
      color: #eff0f0;
    }
    @media screen and (max-width: 991.98px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  &__brand {
    @media screen and (max-width: 991.98px) {
      margin-bottom: 12px !important;
    }
  }
  &__launching {
    margin-bottom: 12px !important;
    margin-top: 8px !important;
  }
}

.atlantis-logo {
  width: 72px;
  height: 17px;
  @media screen and (max-width: 767.98px) {
    width: 58px;
    height: 14px;
  }
}

.logo {
  width: 112px;
  height: 44px;
  @media screen and (max-width: 991.98px) {
    width: 100px;
    height: 40px;
  }
}

.storeIcons {
  width: 114px;
  height: 32px;
  @media screen and (max-width: 767.98px) {
    width: 80px;
    height: 24px;
  }
}
