.nav {
  background-color: #151c2f;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 16px;
  padding-bottom: 16px;
  @media screen and (max-width: 991.98px) {
    padding-left: 36px;
    padding-right: 20px;
  }
  @media screen and (max-width: 767.98px) {
    padding-left: 16px;
    padding-right: 8px;
  }
  &__item {
    font-family: 'Lato', sans-serif;
    color: white !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    &--active {
      color: #13fdfc !important;
      text-shadow: 1px 1px 6px #5fdcdd;
    }
    @media screen and (max-width: 991.98px) {
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 32px;
      border-bottom: #20335f 0.5px solid;
      margin-top: 24px;
      margin-left: 12px;
      margin-right: 12px;
      font-weight: 400;
    }
  }
  &__logo {
    width: 92px;
    height: 33px;
    @media screen and (max-width: 991.98px) {
      width: 108px;
      height: 39px;
    }
    @media screen and (max-width: 767.98px) {
      width: 64px;
      height: 23px;
    }
    @media screen and (min-width: 1900px) {
      width: 129px;
      height: 46px;
    }
  }
}

.persona-button {
  text-decoration: none;
  border-radius: 20px;
  border: solid white;
  border-width: 1px;
  height: 36px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    height: 32px;
    width: 120px;
  }

  &__title {
    font-family: 'Lato', sans-serif;
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__arrow {
    width: 20px;
    height: 16px;
    margin-left: 8px;
    animation: nudge 1s linear infinite;
    @keyframes nudge {
      0%,
      100% {
        transform: translate(0, 0);
      }

      50% {
        transform: translate(5px, 0);
      }
    }
  }
  &:hover {
    text-decoration: none;
  }
}

.line {
  width: 16px;
  height: 2px;
  border-radius: 20px;
  background-color: #76f8f7;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:nth-child(2) {
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.expanded-navbar {
  @media screen and (max-width: 991.98px) {
    height: 100vh;
  }
}

$rotate-by: 5px;
#hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY($rotate-by) rotate(45deg);
  -ms-transform: translateY($rotate-by) rotate(45deg);
  -o-transform: translateY($rotate-by) rotate(45deg);
  transform: translateY($rotate-by) rotate(45deg);
}

#hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-$rotate-by) rotate(-45deg);
  -ms-transform: translateY(-$rotate-by) rotate(-45deg);
  -o-transform: translateY(-$rotate-by) rotate(-45deg);
  transform: translateY(-$rotate-by) rotate(-45deg);
}
